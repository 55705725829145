module.exports = {
  background: '#FFFFFF',
  backgroundDark: '#E7ECF0',

  primary: '#53B4DF',
  primaryLight: '#a9d9ef',
  primaryDark: '#2081ac',

  secondary: '#414141',
  secondaryLight: '#A4A8AA',
  secondaryDark: '#1f1f1f',
};
